<template>
  <div id="gantt">
    <svg
      :width="window.width"
      :height="window.height"
      >
        <g v-for="(item, index) in items" :transform="'translate('+(item.x ? item.x : 0)+', ' + (item.y ? item.y : 0) + ')'" :key="index" :id="'g-'+index" @mouseover="onSelect(item)">
          <text
            v-if="item.type=='text'"
            :x="0"
            :y="item.height*0.9"
            :_width="item.width"
            :_height="item.height"
            :font-size="item.height"
            :font-family="item.font"
            :fill="item.color"
            :text-anchor="item.textAnchor"
            :style="item.style"
          >{{item.text}}</text>
          <rect
            v-else-if="item.type == 'rect' && item.active == true"
            :x="0"
            :y="0"
            :width="item.width >= 0 ? item.width : 0"
            :height="item.height"
            :fill="item.color"
            :fill-opacity="item.opacity"
            :rx="item.rx"
            :ry="item.ry"
          ></rect>
          <ellipse
            v-else-if="item.type=='ellipse'"
            :cx="item.width/2"
            :cy="item.height/2"
            :rx="item.width/2"
            :ry="item.height/2"
            :fill="item.color"
          ></ellipse>
          <line
            v-else-if="item.type=='line'"
            :x1="item.x1"
            :y1="item.y1"
            :x2="item.x2"
            :y2="item.y2"
            :stroke="item.stroke"
            :stroke-width="item.strokeWidth"
            :stroke-dasharray="item.strokeDashArray"
          ></line>
          <polygon
            v-else-if="item.type=='polygon'"
            :points="item.points"
            :style="item.style"
          ></polygon>
        </g>
      </svg>
  </div>
</template>

<script>
export default {
  name: 'Gantt',
  props: {
    data: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: false
    },
    changeData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      w: window.innerWidth,
      window: {
        width: 0,
        height: 0
      },
      line: {
        height: 42,
        line: 0
      },
      bar: {},
      dates: {
        min: new Date(),
        max: new Date(),
        days: 0,
        width: 50,
        initial: 60,
        cols: 4,
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        x: 0,
        y: 0,
        years: []
      },
      today: true,
      colors: {
        hex: '#999999',
        a: 1
      },
      items: [],
      screenOtimize: {
        days: 10000,
        width: 700
      },
      otimized: false,
      info: 'visualização até 300 dias'
    }
  },
  methods: {
    handle () {
      // define window
      this.window.width = (window.innerWidth * 90) / 100
      if (this.window.width < 500) {
        this.screenOtimize.days = 100
        this.info = 'visualização até 100 dias'
      }
      // define start and end date
      this.setDates()
      // set items
      this.isOtimized()
      let height = this.data.filter((item) => {
        return this.isFree(item) === true
      })
      this.window.height = (height.length * 42) + 30 + 50
      this.setItems()
      // this.data.forEach(element => {
      //   this.setGrid(element.start)
      //   this.setGrid(element.end)
      // })
      let first = 0
      for (let i = new Date(this.dates.min); i < this.dates.max; i.setDate(i.getDate() + 30)) {
        if (first !== 0) {
          this.setGridCols(i)
        }
        first = 1
      }
      // this.items.pop()
      // this.setGrid('2019-03-11')
      this.setToday()
      // this.setCols()
      if (this.otimized) {
        this.setInfo()
      }
    },
    setInfo () {
      this.items.push({
        type: 'text',
        textAnchor: 'left',
        active: true,
        x: 0,
        y: this.window.height - 20,
        text: this.info,
        width: 0,
        height: 10,
        font: 'Montserrat',
        color: '#000000'
      })
    },
    isOdd (num) {
      return num % 2
    },
    isFree (element) {
      if (this.otimized) {
        let between = this.getQtDay(element.start, this.dates.max)
        // console.log(between)
        if (between < this.screenOtimize.days) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    isOtimized () {
      if (this.dates.days > this.screenOtimize.days || this.window.width < this.screenOtimize.width) {
        this.otimized = true
        this.dates.min = new Date(Math.min.apply(null, this.data.map(object => {
          if (this.isFree(object)) {
            return object.start
          }
          return this.dates.max
        })))
        this.dates.days = this.getQtDay(this.dates.min, this.dates.max)
        this.dates.cols = (this.window.width - this.dates.initial) / this.dates.width
      }
      return this.otimized
    },
    setItems () {
      this.data.forEach(element => {
        if (this.isFree(element)) {
          let y = this.line.line === 0 ? 0 : this.line.line * this.line.height
          this.items.push({
            type: 'rect',
            active: true,
            opacity: 1,
            x: 0,
            y: y,
            width: 10000,
            height: 42,
            color: this.isOdd(this.line.line) ? '#f5f5f5' : '#ffffff',
            rx: 0,
            ry: 0
          })
          let qtDays = this.getQtDay(this.dates.min, element.start)
          let x = ((this.window.width * qtDays) / this.dates.days)
          this.items.push({
            type: 'text',
            active: true,
            x: x - 56,
            y: y + 13,
            text: element.lt,
            width: 300,
            height: 12,
            font: 'Montserrat',
            color: '#000000'
          })
          let qtDaysEnd = this.getQtDay(this.dates.min, element.end)
          let width = (((this.window.width * qtDaysEnd) / this.dates.days)) - x
          this.items.push({
            type: 'rect',
            active: true,
            opacity: 1,
            x: x,
            y: y + 8,
            width: width,
            height: 26,
            color: '#333333',
            rx: 5,
            ry: 5
          })
          let percent = (width * element.percent) / 100
          if (element.percent === 100) {
            this.items.push({
              type: 'rect',
              active: true,
              opacity: 1,
              x: x,
              y: y + 8,
              width: width,
              height: 26,
              color: '#0084D6',
              rx: 5,
              ry: 5
            })
          } else {
            this.items.push({
              type: 'rect',
              active: true,
              opacity: 1,
              x: x,
              y: y + 8,
              width: percent,
              height: 26,
              color: '#0084D6',
              rx: 5,
              ry: 5
            })
          }
          if (element.percent < 100) {
            this.items.push({
              type: 'rect',
              active: true,
              opacity: 1,
              x: x + 10,
              y: y + 8,
              width: percent - 10,
              height: 26,
              color: '#0084D6',
              rx: 0,
              ry: 0
            })
          }
          if (element.percent > 9) {
            this.items.push({
              type: 'text',
              active: true,
              textAnchor: 'left',
              x: element.percent > 50 ? x + percent - 40 : x + percent - 25,
              y: y + 13,
              text: element.percent + '%',
              width: 300,
              height: 12,
              font: 'Montserrat',
              color: '#ffffff'
            })
          }
          // console.log('Bar')
          // console.log(y)
          // console.log(qtDays)
          // console.log(x)
          // // console.log(x - 60)
          // console.log(qtDaysEnd)
          // console.log(width)
          // console.log(percent + '%')
          this.line.line += 1
        }
      })
    },
    setDates () {
      this.dates.min = new Date(Math.min.apply(null, this.data.map(object => {
        var item = object.start
        return item
      })))
      this.dates.min.setDate(this.dates.min.getDate() - 22)
      this.dates.max = new Date(Math.max.apply(null, this.data.map(object => {
        var item = object.end
        return item
      })))
      this.dates.days = this.getQtDay(this.dates.min, this.dates.max)
      this.dates.cols = Math.floor((this.window.width - this.dates.initial) / this.dates.width)
    },
    getQtDay (start, end) {
      return (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 3600 * 24)
    },
    setGrid (day) {
      let today = new Date(day)
      let balum = {
        a: this.window.height - 42,
        b: this.window.height - 37,
        c: this.window.height - 32,
        xa: 0,
        xb: 0,
        xc: 0,
        xd: 0
      }
      if ((today >= this.dates.min && today <= this.dates.max)) {
        let qt = this.getQtDay(this.dates.min, today)
        let x = ((this.window.width * qt) / this.dates.days)
        this.items.push({
          type: 'line',
          active: true,
          x1: x,
          y1: '0',
          x2: x,
          y2: this.window.height - 50,
          y: false,
          stroke: 'black',
          strokeWidth: '2',
          strokeDashArray: '2,4'
        })
        this.items.push({
          type: 'text',
          textAnchor: 'left',
          active: true,
          x: x - 10,
          y: this.window.height - 150,
          text: this.dates.months[today.getMonth()] + ' ' + today.getDate(),
          width: 0,
          height: 12,
          font: 'Montserrat',
          color: '#000',
          style: 'font-weight: 500; writing-mode: tb; glyph-orientation-vertical: 1;'
        })
        balum.xa = x - 56
        balum.xb = x - 51
        balum.xc = x - 56
        balum.xd = x - 61
      }
    },
    setGridCols (day) {
      let today = new Date(day)
      if ((today >= this.dates.min && today <= this.dates.max)) {
        let qt = this.getQtDay(this.dates.min, today)
        let x = ((this.window.width * qt) / this.dates.days)
        this.items.push({
          type: 'text',
          textAnchor: 'left',
          active: true,
          x: x - 20,
          y: this.window.height - 80,
          text: today.getDate() + ' ' + this.dates.months[today.getMonth()],
          width: 0,
          height: 12,
          font: 'Montserrat',
          color: '#000',
          style: 'font-weight: 500;'
        })
        if ((today.getMonth() === 0) && (this.dates.years.filter((item) => { return item === today.getMonth() }).length <= 0)) {
          this.dates.years.push(today.getMonth())
          this.items.push({
            type: 'text',
            textAnchor: 'left',
            active: true,
            x: x - 20,
            y: this.window.height - 65,
            text: today.getFullYear(),
            width: 50,
            height: 12,
            font: 'Montserrat',
            color: '#000000',
            style: 'font-weight: 500;'
          })
        }
      }
    },
    setToday () {
      let today = new Date()
      let balum = {
        a: this.window.height - 42,
        b: this.window.height - 37,
        c: this.window.height - 32,
        xa: 0,
        xb: 0,
        xc: 0,
        xd: 0
      }
      if ((today >= this.dates.min && today <= this.dates.max)) {
        let qt = this.getQtDay(this.dates.min, today)
        let x = ((this.window.width * qt) / this.dates.days)
        this.items.push({
          type: 'line',
          active: true,
          x1: x,
          y1: '0',
          x2: x,
          y2: this.window.height - 50,
          y: false,
          stroke: 'black',
          strokeWidth: '2',
          strokeDashArray: '2,4'
        })
        this.items.push({
          type: 'rect',
          active: true,
          opacity: 1,
          x: x - 59,
          y: this.window.height - 50,
          width: 60,
          height: 30,
          color: '#000000',
          rx: 0,
          ry: 0
        })
        this.items.push({
          type: 'rect',
          active: true,
          opacity: 1,
          x: x - 69,
          y: this.window.height - 50,
          width: 30,
          height: 30,
          color: '#000000',
          rx: 10,
          ry: 10
        })
        this.items.push({
          type: 'text',
          textAnchor: 'left',
          active: true,
          x: x - 41,
          y: this.window.height - 45,
          text: 'Hoje',
          width: 0,
          height: 14,
          font: 'Montserrat',
          color: '#ffffff'
        })
        balum.xa = x - 56
        balum.xb = x - 51
        balum.xc = x - 56
        balum.xd = x - 61
        this.items.push({
          x: x,
          y: false,
          active: true,
          type: 'polygon',
          points: balum.xa + ',' + balum.a + ' ' + balum.xb + ',' + balum.b + ' ' + balum.xc + ',' + balum.c + ' ' + balum.xd + ',' + balum.b,
          style: 'fill:white;'
        })
      }
    },
    setCols () {
      var n = 0
      for (var i = 1; i <= this.dates.cols; i++) {
        this.dates.x = this.dates.x + this.dates.width
        if (i === 1) {
          this.createDate(this.dates.min)
        } else if (i === Math.floor(this.dates.cols)) {
          this.dates.x = this.window.width - 40
          this.createDate(this.dates.max)
        } else {
          if (n === 0) {
            n = 1
          } else {
            n = 0
            var now = (this.dates.days * this.dates.x) / this.window.width
            this.createDate(new Date(this.dateAddDay(this.dates.min, Math.floor(now))))
          }
        }
      }
    },
    createDate (date) {
      this.items.push({
        type: 'text',
        textAnchor: 'left',
        active: true,
        x: this.dates.x,
        y: this.window.height - 80,
        text: this.dates.months[date.getMonth()] + ' ' + date.getDate(),
        width: 50,
        height: 12,
        font: 'Montserrat',
        color: '#000000',
        style: 'font-weight: 500;'
      })
      if ((date.getMonth() === 0) && (this.dates.years.filter((item) => { return item === date.getMonth() }).length <= 0)) {
        this.dates.years.push(date.getMonth())
        this.items.push({
          type: 'text',
          textAnchor: 'left',
          active: true,
          x: this.dates.x,
          y: this.window.height - 65,
          text: date.getFullYear(),
          width: 50,
          height: 12,
          font: 'Montserrat',
          color: '#000000',
          style: 'font-weight: 500;'
        })
      }
    },
    dateAddDay (date, days) {
      var result = new Date(date)
      result = result.setDate(result.getDate() + days)
      return result
    },
    dateLessDay (date, days) {
      var result = date.setDate(date.getDate() + days)
      return result
    },
    getItemData (item) {
      let line = item.y / this.line.height <= 0 ? 0 : item.y / this.line.height
      let data = this.data.filter((item) => {
        return this.isFree(item) === true
      })
      if (line < data.length) {
        line = this.data.findIndex(element => element === data[Math.floor(line)])
      }
      return line
    },
    onSelect (item) {
      if (item.y) {
        let line = this.otimized ? this.getItemData(item) : item.y / this.line.height <= 0 ? 0 : item.y / this.line.height
        if (this.otimized) {
          if (line < this.data.length) {
            this.$emit('selected', Math.floor(line))
            this.printSelected(item.y)
          }
        } else {
          if (line < this.data.length) {
            this.$emit('selected', Math.floor(line))
            this.printSelected(item.y)
          }
        }
      }
    },
    printSelected (y) {
      let line = 0
      if (y < 42) {
        y = 0
      } else {
        line = y / this.line.height <= 0 ? 0 : y / this.line.height
      }
      this.items = this.items.filter((item) => {
        return item.selected !== true
      })
      line = Math.floor(line)
      switch (Math.floor(line)) {
        case 0:
          this.items.push({
            type: 'rect',
            active: true,
            opacity: '.5',
            x: 0,
            y: 42,
            width: 10000,
            height: this.window.height - 125,
            color: '#fff',
            rx: 0,
            ry: 0,
            selected: true
          })
          break
        default:
          this.items.push({
            type: 'rect',
            active: true,
            opacity: '.5',
            x: 0,
            y: -1,
            width: 10000,
            height: ((line + 1) * 42) - 47,
            color: '#fff',
            rx: 0,
            ry: 0,
            selected: true
          })
          this.items.push({
            type: 'rect',
            active: true,
            opacity: '.5',
            x: 0,
            y: ((line + 1) * 42),
            width: 10000,
            height: this.window.height - 125 - ((line + 1) * 42 - 42),
            color: '#fff',
            rx: 0,
            ry: 0,
            selected: true
          })
      }
      // this.items.push({
      //   type: 'rect',
      //   active: true,
      //   opacity: '.5',
      //   x: 0,
      //   y: y,
      //   width: 10000,
      //   height: 43,
      //   color: '#ccc',
      //   rx: 0,
      //   ry: 0,
      //   selected: true
      // })
    }
  },
  watch: {
    w: function (val) {
      this.window = {
        width: 0,
        height: 0
      }
      this.line = {
        height: 42,
        line: 0
      }
      this.bar = {}
      this.dates = {
        min: new Date(),
        max: new Date(),
        days: 0,
        width: 50,
        initial: 60,
        cols: 4,
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        x: 0,
        y: 0,
        years: []
      }
      this.today = true
      this.colors = {
        hex: '#999999',
        a: 1
      }
      this.items = []
      this.screenOtimize = {
        days: 10000,
        width: 700
      }
      this.otimized = false
      this.info = 'visualização até 300 dias'
      this.handle()
    },
    data: function (val) {
      this.window = {
        width: 0,
        height: 0
      }
      this.line = {
        height: 42,
        line: 0
      }
      this.bar = {}
      this.dates = {
        min: new Date(),
        max: new Date(),
        days: 0,
        width: 50,
        initial: 60,
        cols: 4,
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        x: 0,
        y: 0,
        years: []
      }
      this.today = true
      this.colors = {
        hex: '#999999',
        a: 1
      }
      this.items = []
      this.screenOtimize = {
        days: 10000,
        width: 700
      }
      this.otimized = false
      this.info = 'visualização até 300 dias'
      this.handle()
    },
    changeData: function (val) {
      if (val.lt) {
        var result = this.items.filter(item => {
          return item.text === val.lt
        })
        this.onSelect(result[0])
      } else {
        this.items = this.items.filter((item) => {
          return item.selected !== true
        })
      }
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.w = window.innerWidth
    })
  },
  created () {
    // window.addEventListener('resize', this.handle())
    this.handle()
  },
  destroyed () {
    // window.removeEventListener('resize', this.handle())
  }
}
</script>

<style lang="scss">
</style>
